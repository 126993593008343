export const applyMobileSpecificTransformations = (tableRows: any, tableHeaders: any) => {
  tableHeaders = tableHeaders ?? []
  if (tableHeaders.length > 0) {
    tableRows.forEach((row: any) => {
      Array.from(row.cells).forEach((cell: any, index: number) => {
        if (!cell.querySelector('.mobile-table-header')) {
          const header = document.createElement('span')
          header.textContent = tableHeaders[index]
          header.className = 'mobile-table-header'

          const isTextContent = cell.innerText.trim() !== ''
          const content = isTextContent ? cell.innerText.trim() : cell.innerHTML

          const data = document.createElement('span')
          data.className = isTextContent ? 'mobile-table-data' : 'mobile-table-html'
          if (isTextContent) {
            data.textContent = content
          } else {
            data.innerHTML = content
          }

          cell.innerHTML = ''
          cell.appendChild(header)
          cell.appendChild(data)
        }
      })
    })
  }
}

export const revertMobileSpecificTransformations = (tableRows: any) => {
  if (tableRows.length === 0 || tableRows[0].cells.length === 0) return

  if (tableRows[0].cells[0].querySelector('.mobile-table-header')) {
    tableRows.forEach((row: any) => {
      Array.from(row.cells).forEach((cell: any) => {
        const header = cell.querySelector('.mobile-table-header')
        const data = cell.querySelector('.mobile-table-data, .mobile-table-html')

        if (header && data) {
          if (data.classList.contains('mobile-table-html')) {
            cell.innerHTML = data.innerHTML
          } else {
            cell.textContent = data.textContent
          }
          header.remove()
        }
      })
    })
  }
}

export const cleanUpStylesAfterWindowResize = (tableRows: any, isMobile: boolean) => {
  if (!isMobile) {
    tableRows.forEach((row: any) => {
      Array.from(row.cells).forEach((cell: any) => {
        const header = cell.querySelector('.mobile-table-header')
        const data = cell.querySelector('.mobile-table-data, .mobile-table-html')

        if (header && data) {
          if (data.classList.contains('mobile-table-html')) {
            cell.innerHTML = data.innerHTML
          } else {
            cell.textContent = data.textContent
          }
          header.remove()
        }
      })
    })
  }
}

export const extractTableData = (htmlContent) => {
  const rowRegex = /<tr>(.*?)<\/tr>/gs
  const cellRegex = /<td>(.*?)<\/td>/gs

  const rows = [...htmlContent.matchAll(rowRegex)].map((row) => row[1])
  const tableData = rows.map((row) => {
    return [...row.matchAll(cellRegex)].map((cell) => cell[1])
  })

  return tableData
}

export const reformatTableData = (tableData) => {
  const headers = tableData[0]
  const values = tableData.slice(1)

  let formattedData = []
  values.forEach((valueRow) => {
    headers.forEach((header, index) => {
      formattedData.push([header, valueRow[index]])
    })
  })

  return { headers, formattedData }
}

export const generateNewTableHtml = (headers, formattedData) => {
  let newTableHtml = '<table><tbody>'
  let groupCounter = 0

  formattedData.forEach((row, index) => {
    if (index % headers.length === 0) {
      groupCounter++
    }
    const groupClass = groupCounter % 2 === 1 ? 'group1' : 'group2'
    newTableHtml += `<tr class="${groupClass}"><td>${row[0]}</td><td>${row[1]}</td></tr>`
  })

  newTableHtml += '</tbody></table>'
  return newTableHtml
}
